import parse from "html-react-parser";
import * as React from "react";
import { FC, useState } from "react";

type TabsProps = {
  intl: any;
  descriptionTitle: string;
  preparationTitle: string;
  description: string;
  preparation: string;
};

//@ts-ignore
const Tabs: FC<TabsProps> = ({
  intl,
  descriptionTitle,
  preparationTitle,
  description,
  preparation,
}) => {
  const [toggleState, setToggleState] = useState(1);

  return (
    <>
      <div className="h-[2.5rem] mt-[2rem] flex border border-b border-[#618EE4] border-opacity-50 border-t-0 border-x-0">
        <div
          className={
            toggleState === 1
              ? "analysis_detail_active  w-[8rem] flex justify-center items-center"
              : " w-[8rem] flex justify-center items-center"
          }
          onClick={() => setToggleState(1)}
        >
          <p className="sm:text-[1.125rem] text-[1rem]">
            {parse(
              intl.formatMessage({
                id: descriptionTitle,
              })
            )}
          </p>
        </div>
        <div
          className={
            toggleState === 2
              ? "analysis_detail_active w-[15rem] flex justify-center items-center"
              : 'w-[15rem] flex justify-center items-center"'
          }
          onClick={() => setToggleState(2)}
        >
          {parse(
            intl.formatMessage({
              id: preparationTitle,
            })
          )}
        </div>
      </div>
      <div className={toggleState === 1 ? "mt-[2rem]" : "hidden mt-[2rem]"}>
        <p className="font-medium text-[1rem]">
          {parse(
            intl.formatMessage({
              id: description,
            })
          )}
        </p>
      </div>
      <div className={toggleState === 2 ? "mt-[2rem]" : "hidden mt-[2rem]"}>
        <p className="font-medium text-[1rem]">
          {parse(
            intl.formatMessage({
              id: preparation,
            })
          )}
        </p>
      </div>
    </>
  );
};

export default Tabs;
