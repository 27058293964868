import * as React from "react";
import Layout, { AppStateContext } from "../components/Layout/Layout";
import parse from "html-react-parser";
import Calendar from "../images/calendar_blue.svg";
import Cart from "../images/cart.svg";
import Tabs from "../components/Tabs";
import { injectIntl } from "gatsby-plugin-intl";
import BreadCrumbs from "../BreadCrumbs";
import "./covid.css";
import Covid from "../images/covid.webm";
import { useRef } from "react";
import { useQuery } from "@apollo/client";
import { GET_ANALYSIS_BY_TYPE } from "../query/getAnalysis";
import { addToBasket, findItemInBasket } from "../utils/helper-functions";

//@ts-ignore
const covid = ({ intl, pageContext }) => {
  const videoRef = useRef(null);
  const handleMouseOut = () => {
    // @ts-ignore
    videoRef.current.pause();
  };
  const handleMouseOver = () => {
    // @ts-ignore
    videoRef.current.play();
  };

  const { data, loading, error } = useQuery(GET_ANALYSIS_BY_TYPE, {
    variables: {
      filters: {
        covid: {
          eq: true,
        },
      },
    },
  });

  if (loading) return "";

  if (error) return `Error! ${error.message}`;

  return (
    <Layout>
      <div className="flex items-center justify-center bg-[#FAFAFA] ">
        <div className="mb-[10rem] flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className="ml-[0.2rem] mt-[2rem] mb-[2rem] ">
            <BreadCrumbs
              pageContext={pageContext}
              title="ServiceMenu.PCR.Title"
            />
          </div>
          <div className="covid_banner flex  justify-end w-auto bg-[#EDF2FC] h-[10rem] rounded-[1rem]">
            <video
              className="xl:mr-[9.5rem] lg:mr-[8rem] sm:mr-[9.5rem] sm:mt-[0rem] xs:mt-[2rem] cursor-pointer lg:w-[11rem] lg:h-[11rem] sm:w-[11rem] sm:h-[11rem] xs:w-[9rem] xs:h-[9rem]"
              ref={videoRef}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              muted
            >
              <source
                src="../images/covid_safari.mov"
                type='video/mov; codecs="hvc1"'
              />
              <source src={Covid} type="video/webm;" />
            </video>
          </div>
          <h2 className="mt-[2rem] text-[2rem] font-semibold text-[#001439]">
            {intl.formatMessage({ id: "ServiceMenu.PCR.Title" })}
          </h2>

          <div className="mt-[2rem] grid lg:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 lg:gap-[2rem] xs:gap-[1rem]">
            {data.analyses.data.map((analysis: any) => (
              <div className="xl:w-[24rem] lg:w-[19rem] sm:w-[21rem] xs:w-[19rem] bg-[#EDF2FC]  flex flex-col  h-auto justify-between   mb-2  rounded-[1rem] p-[1rem] px-4 border border-[#618EE4] border-opacity-25 border-2 ">
                {/*Description*/}
                <div className="flex flex-col ">
                  <h4 className="font-semibold justify-around  text-[1.25rem] text-left xl:leading-[2rem] lg:leading-[1.7rem] sm:leading-[1.6rem] xs:leading-[1.5rem] sm:h-[6rem] xs:h-[5.2rem]">
                    {analysis.attributes.title}
                  </h4>
                  <div className="mt-[1.4rem] flex justify-left">
                    <img src={Calendar} alt="" className="" />
                    <p className="font-semibold text-[1rem] pl-[8px]">
                      {analysis.attributes.term}
                    </p>
                    <p className="font-semibold text-[1rem] pl-[4px]">
                      {parse(
                        intl.formatMessage({
                          id: `ServiceMenu.PCR.TermDay`,
                        })
                      )}
                    </p>
                  </div>
                </div>
                {/*Price*/}
                <div className="mt-[1.4rem] flex flex-row justify-between  gap-[4.4rem] text-right">
                  <h2 className="font-semibold text-[1.5rem] text-[#001439] opacity-75">
                    {analysis.attributes.price}
                    <span className="ml-[4px]">₸</span>
                  </h2>
                  <AppStateContext.Consumer>
                    {({ setBasketCount }) => (
                      <button
                        className="flex items-center justify-center w-[9rem] h-[2.8rem] bg-[#618EE4] rounded-[0.6rem]"
                        onClick={() => addToBasket(analysis.id, setBasketCount)}
                      >
                        <img src={Cart} alt="Cart" className="cart" />
                        {findItemInBasket(analysis.id) ? (
                          <p className="font-semibold text-[1rem] text-white ml-[0.5rem]">
                            {parse(
                              intl.formatMessage({
                                id: `ServiceMenu.AnalysisAndPrices.InBasket`,
                              })
                            )}
                          </p>
                        ) : (
                          <p className="font-semibold text-[1rem] text-white ml-[0.5rem]">
                            {parse(
                              intl.formatMessage({
                                id: `ServiceMenu.AnalysisAndPrices.AddToBasket`,
                              })
                            )}
                          </p>
                        )}
                      </button>
                    )}
                  </AppStateContext.Consumer>
                </div>
              </div>
            ))}
          </div>

          <h2 className="mt-[2rem] text-[2rem] font-semibold text-[#001439] sm:flex xs:hidden">
            {intl.formatMessage({ id: "ServiceMenu.PCR.Subtitle" })}
          </h2>

          {/*Tab*/}
          <Tabs
            intl={intl}
            descriptionTitle={`ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Description.Title`}
            preparationTitle={`ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Preparation.Title`}
            description={`ServiceMenu.PCR.Description`}
            preparation={`ServiceMenu.PCR.Preparation`}
          />
        </div>
      </div>
    </Layout>
  );
};

// @ts-ignore
export default injectIntl(covid);
