import React from "react";
import {
  localStorageAddAnalysisToBasket,
  localStorageGetBasket,
  localStorageGetBasketCount,
} from "../service/storage";

export const findItemInBasket = (analysisId: string) => {
  const array = localStorageGetBasket();
  let found: string[] = [];
  for (let i = 0; i < array.analyses.length; i++) {
    if (array.analyses[i] === analysisId) {
      found.push(analysisId);
      return found.length !== 0;
    }
  }
};

export const addToBasket = (
  analysisId: string,
  setBasketCount: React.Dispatch<React.SetStateAction<number>>
) => {
  localStorageAddAnalysisToBasket(analysisId);
  setBasketCount(localStorageGetBasketCount());
};
