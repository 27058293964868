import { gql } from "@apollo/client";

export const GET_ANALYSIS = gql`
  query allAnalyses($locale: I18NLocaleCode, $pagination: PaginationArg) {
    analyses(locale: $locale, pagination: $pagination) {
      data {
        attributes {
          title
          description
          price
          term
          analysis_material {
            data {
              attributes {
                title
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;
export const GET_ANALYSIS_BY_TYPE = gql`
  query analysisByType(
    $locale: I18NLocaleCode
    $filters: AnalysisFiltersInput
    $pagination: PaginationArg
  ) {
    analyses(locale: $locale, filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          title
          description
          price
          term
          analysis_material {
            data {
              attributes {
                title
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

export const GET_ANALYSIS_GROUP = gql`
  query analysisGroups($locale: I18NLocaleCode, $pagination: PaginationArg) {
    analysisGroups(locale: $locale, pagination: $pagination) {
      data {
        id
        attributes {
          title
          price
          icon {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ANALYSIS_GROUP_BY_ID = gql`
  query analysisGroup($locale: I18NLocaleCode, $id: ID) {
    analysisGroup(locale: $locale, id: $id) {
      data {
        id
        attributes {
          title
          description
          instruction
          price
          analyses(pagination: { limit: -1 }) {
            data {
              id
              attributes {
                title
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ANALYSIS_TYPE = gql`
  query analysesType($locale: I18NLocaleCode) {
    analysisTypes(locale: $locale) {
      data {
        id
        attributes {
          title
        }
      }
    }
  }
`;
